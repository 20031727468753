import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTestimonial,
  saveTestimonialPhoto,
} from "../store/actions/dashboardAction";
import FormTextField from "../forms/FormTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

const schema = yup.object({
  input_8: yup.string().trim().required("Cannot be empty"),
  input_22: yup.string().trim().required("Cannot be empty"),
  input_2: yup.string().trim().required("Cannot be empty"),
});

const TestimonialUI = ({ closeModal }) => {
  const dispatch = useDispatch();
  const [img, setImg] = useState({
    beforeFront: "",
    afterFront: "",
    beforeBack: "",
    afterBack: "",
    beforeSide: "",
    afterSide: "",
  });
  const [filePath, setFilePath] = useState({
    beforeFront: "",
    afterFront: "",
    beforeBack: "",
    afterBack: "",
    beforeSide: "",
    afterSide: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [pageType, setPageType] = useState("form");
  const [uploadImg, setUploadImage] = useState(false);
  const [error, setError] = useState("");
  const [imgLoading, setImgLoading] = useState(false);

  const { loginUser } = useSelector((state) => state.auth);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (loginUser?.name) {
      setValue("input_8", loginUser?.name);
    }
  }, [loginUser]);

  const saveData = async (data) => {
    if (
      uploadImg
        ? (filePath?.beforeBack ||
            filePath?.beforeFront ||
            filePath?.beforeSide) &&
          (filePath?.afterBack || filePath?.afterFront || filePath?.afterSide)
        : true
    ) {
      const payload = {
        ...data,
        input_24: loginUser?.useremail,
        input_12: uploadImg ? "Yes" : "No",
        input_18: filePath?.beforeFront,
        input_20: filePath?.afterFront,
        input_25: filePath?.beforeBack,
        input_26: filePath?.afterBack,
        input_27: filePath?.beforeSide,
        input_28: filePath?.afterSide,
      };

      setIsLoading(true);
      const res = await dispatch(addTestimonial(payload));

      if (res.status === "success") {
        setPageType("success");
      }
      setIsLoading(false);
    } else {
      setError("Please submit at least one before photo and one after photo.");

      setTimeout(() => {
        setError("");
      }, 3000);
    }
  };

  function getBase64(file, type) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setImg({ ...img, [type]: reader.result });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const uploadImage = async (e, type) => {
    if (e.target.files && e.target.files.length > 0) {
      getBase64(e.target.files[0], type);
      var data = new FormData();
      data.append("testimonialPhoto", e.target.files[0]);
      setImgLoading(true);
      const res = await saveTestimonialPhoto(data);
      if (res.filePath) {
        setFilePath({ ...filePath, [type]: res.filePath });
      }
      setImgLoading(false);
    }
  };

  return (
    <div className="modal-content modal-content-refer">
      <div className="modal-header">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          onClick={closeModal}
          aria-label="Close">
          <i className="bx bx-plus bx-rotate-45"></i>
        </button>
        <h5 className="mb-2 bold">Add Testimonial</h5>
      </div>
      <div className="modal-body">
        {pageType === "form" ? (
          <form
            className="d-flex flex-column justify-content-between h-100"
            onSubmit={handleSubmit(saveData)}>
            <div>
              <FormTextField
                divClassName="col-md-12"
                innerDivClassName="col-md-12"
                label="First Name, Last Initial"
                fieldName="input_8"
                register={register}
                errors={errors}
              />
              <FormTextField
                divClassName="col-md-12 mt-3"
                innerDivClassName="col-md-12"
                label="Achievement"
                fieldName="input_22"
                register={register}
                errors={errors}
              />

              <FormTextField
                divClassName="col-md-12 mt-3"
                innerDivClassName="col-md-12"
                label="Testimonial"
                fieldName="input_2"
                register={register}
                errors={errors}
              />

              <label className="col-12 mt-2 fb-label">
                Would you like to upload before and after photos? You will
                receive a one-time BTM2 coupon code for your submission!
                <p style={{ fontSize: 12 }}>
                  (By uploading your photos, you are granting Breakthrough M2
                  permission to use your submission publicly.) 
                </p>
              </label>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="image"
                    id="yes"
                    checked={uploadImg}
                    onChange={() => {
                      setUploadImage(true);
                    }}
                  />
                  <label
                    className="form-check-label-redeem"
                    for="yes"
                    style={{ fontSize: 16 }}>
                    Yes
                  </label>
                </div>
                <div
                  className="form-check"
                  style={{ marginLeft: 20 }}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="image"
                    id="no"
                    checked={!uploadImg}
                    onChange={() => {
                      setUploadImage(false);
                    }}
                  />
                  <label
                    className="form-check-label-redeem"
                    for="no"
                    style={{ fontSize: 16 }}>
                    No
                  </label>
                </div>
              </div>

              {uploadImg && (
                <>
                  <label className="col-12 mt-3 fb-label">
                    Please submit at least one before photo and one after photo.
                  </label>
                  <label className="col-12 mt-2 fb-label">
                    Before Photo (Front)
                  </label>
                  {img?.beforeFront ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a
                        role="button"
                        style={{
                          color: "red",
                          right: 20,
                          position: "absolute",
                        }}
                        onClick={(e) => {
                          setImg({ ...img, beforeFront: "" });
                          setFilePath({ ...img, beforeFront: "" });
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          />
                        </svg>
                      </a>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: 200,
                          marginTop: 5,
                        }}
                        src={img?.beforeFront}
                      />
                    </div>
                  ) : (
                    <label
                      for="upload-before-front"
                      className="file-upload mb-0"
                      tabindex="0">
                      <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                      <input
                        type="file"
                        id="upload-before-front"
                        className="account-file-input"
                        accept="image/png, image/jpeg, image/jpg, image/heic"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "beforeFront")}
                      />
                    </label>
                  )}
                  <label className="col-12 mt-2 fb-label">
                    After Photo (Front)
                  </label>
                  {img?.afterFront ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a
                        role="button"
                        style={{
                          color: "red",
                          right: 20,
                          position: "absolute",
                        }}
                        onClick={(e) => {
                          setImg({ ...img, afterFront: "" });
                          setFilePath({ ...img, afterFront: "" });
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          />
                        </svg>
                      </a>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: 200,
                          marginTop: 5,
                        }}
                        src={img?.afterFront}
                      />
                    </div>
                  ) : (
                    <label
                      for="upload-after-front"
                      className="file-upload mb-0"
                      tabindex="0">
                      <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                      <input
                        type="file"
                        id="upload-after-front"
                        className="account-file-input"
                        accept="image/png, image/jpeg, image/jpg, image/heic"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "afterFront")}
                      />
                    </label>
                  )}
                  <label className="col-12 mt-2 fb-label">
                    Before Photo (Back)
                  </label>
                  {img?.beforeBack ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}>
                      <a
                        role="button"
                        style={{
                          color: "red",
                          right: 20,
                          position: "absolute",
                        }}
                        onClick={(e) => {
                          setImg({ ...img, beforeBack: "" });
                          setFilePath({ ...img, beforeBack: "" });
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          />
                        </svg>
                      </a>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: 200,
                          marginTop: 5,
                        }}
                        src={img?.beforeBack}
                      />
                    </div>
                  ) : (
                    <label
                      for="upload-before-back"
                      className="file-upload mb-0"
                      tabindex="0">
                      <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                      <input
                        type="file"
                        id="upload-before-back"
                        className="account-file-input"
                        accept="image/png, image/jpeg, image/jpg, image/heic"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "beforeBack")}
                      />
                    </label>
                  )}
                  <label className="col-12 mt-2 fb-label">
                    After Photo (Back)
                  </label>
                  {img?.afterBack ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a
                        role="button"
                        style={{
                          color: "red",
                          right: 20,
                          position: "absolute",
                        }}
                        onClick={(e) => {
                          setImg({ ...img, afterBack: "" });
                          setFilePath({ ...img, afterBack: "" });
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          />
                        </svg>
                      </a>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: 200,
                          marginTop: 5,
                        }}
                        src={img?.afterBack}
                      />
                    </div>
                  ) : (
                    <label
                      for="upload-after-back"
                      className="file-upload mb-0"
                      tabindex="0">
                      <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                      <input
                        type="file"
                        id="upload-after-back"
                        className="account-file-input"
                        accept="image/png, image/jpeg, image/jpg, image/heic"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "afterBack")}
                      />
                    </label>
                  )}
                  <label className="col-12 mt-2 fb-label">
                    Before Photo (Side)
                  </label>
                  {img?.beforeSide ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a
                        role="button"
                        style={{
                          color: "red",
                          right: 20,
                          position: "absolute",
                        }}
                        onClick={(e) => {
                          setImg({ ...img, beforeSide: "" });
                          setFilePath({ ...img, beforeSide: "" });
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          />
                        </svg>
                      </a>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: 200,
                          marginTop: 5,
                        }}
                        src={img?.beforeSide}
                      />
                    </div>
                  ) : (
                    <label
                      for="upload-before-side"
                      className="file-upload mb-0"
                      tabindex="0">
                      <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                      <input
                        type="file"
                        id="upload-before-side"
                        className="account-file-input"
                        accept="image/png, image/jpeg, image/jpg, image/heic"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "beforeSide")}
                      />
                    </label>
                  )}
                  <label className="col-12 mt-2 fb-label">
                    After Photo (Side)
                  </label>
                  {img?.afterSide ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <a
                        role="button"
                        style={{
                          color: "red",
                          right: 20,
                          position: "absolute",
                        }}
                        onClick={(e) => {
                          setImg({ ...img, afterSide: "" });
                          setFilePath({ ...img, afterSide: "" });
                        }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"
                          />
                        </svg>
                      </a>
                      <img
                        style={{
                          maxWidth: "100%",
                          maxHeight: 200,
                          marginTop: 5,
                        }}
                        src={img?.afterSide}
                      />
                    </div>
                  ) : (
                    <label
                      for="upload-after-side"
                      className="file-upload mb-0"
                      tabindex="0">
                      <i className="bx bx-plus-circle bx-lg primary-color mb-3"></i>
                      <input
                        type="file"
                        id="upload-after-side"
                        className="account-file-input"
                        accept="image/png, image/jpeg, image/jpg, image/heic"
                        hidden="true"
                        onChange={(e) => uploadImage(e, "afterSide")}
                      />
                    </label>
                  )}
                </>
              )}
            </div>

            <div className="modal-footer mt-4">
              <button
                className="btn w-100 btn-lg br-10"
                type="submit"
                disabled={isLoading || imgLoading}>
                {isLoading ? "Processing" : "Submit"}
              </button>
            </div>
            {imgLoading && uploadImg && (
              <p style={{ textAlign: "center", marginTop: -20 }}>
                Image is uploading...
              </p>
            )}
            {error && uploadImg && (
              <p style={{ textAlign: "center", color: "red", marginTop: -20 }}>
                {error}
              </p>
            )}
          </form>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <p style={{ fontSize: 18, color: "#1e2a42", fontWeight: 600 }}>
              Testimonial has been submitted!
            </p>
            <button
              className="btn w-50 btn-lg br-10 mt-4"
              onClick={closeModal}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TestimonialUI;
